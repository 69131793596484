// ======================================================================
// Checkout (Your Order) - Quantity Spinner UI component
// ======================================================================

export const quantitySpinner = (function () {
    let interval
    let timeout
    let autoIncrementing = false

    const restrictNumeric = function (event) {
    // Restrict Keypresses to numeric and select non-printable keys
        const keyCode = event.which

        if ((!event.shiftKey && keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
            // Numeric key
            return true
        } else if (event.controlKey || event.metaKey) {
            // allow Ctrl+? & Command+? key combinations
            return true
        } else if ($.inArray(keyCode, [46, 8, 9, 27, 13, 110, 35, 36, 37, 38, 39, 40]) !== -1) {
            // allow select non-printable keys: backspace, delete, tab, escape, home, end, left, right, down, up
            return true
        }
        return false

    }

    const autoIncrementStart = function ($input, increment, min, max) {
        // Increase input value by specified increment (optionally resticted by min & max values)
        // After 400ms increment value every 200ms until interval is cleared.
        let oldValue = parseInt($input.val())
        if (isNaN(oldValue)) {
            oldValue = 0
        }
        let newValue = oldValue + increment

        if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
            // Increment input value
            $input.val(parseInt(newValue))
            $input[0].dispatchEvent(new Event('change', {
                bubbles: true,
                cancelable: true
            }))
            // Auto-increment after delay
            autoIncrementing = true
            window.clearTimeout(timeout)
            timeout = window.setTimeout(function () {
                window.clearInterval(interval)
                interval = window.setInterval(function () {
                    oldValue = parseInt($input.val())
                    if (isNaN(oldValue)) {
                        oldValue = 0
                    }
                    newValue = oldValue + increment
                    if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
                        $input.val(parseInt(newValue))
                        $input[0].dispatchEvent(new Event('change', {
                            bubbles: true,
                            cancelable: true
                        }))
                    } else {
                        // Stop auto incrementing
                        autoIncrementStop()
                    }
                }, 200)
            }, 400)
        }
    }

    const autoIncrementStop = function () {
        // Clear timers and select input text
        window.clearTimeout(timeout)
        window.clearInterval(interval)
        autoIncrementing = false
    }

    const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints
    $('body')
        .on('click', '.field--quantity-spinner .field__input', function () {
            if (!isTouch) {
                $(this).textrange('set', 'all')
            }
        })
        .on('keydown', '.field--quantity-spinner .field__input', restrictNumeric)
        .on('keydown', '.field--quantity-spinner .field__input', function (event) {
            const selling_multiple = parseInt($(this).attr('data-selling_multiple') || 1)
            let increment = 0
            if (event.which === 40) {
                // Down arrow key
                increment = -1 * selling_multiple
            } else if (event.which === 38) {
                // Up arrow key
                increment = selling_multiple
            } else {
                // Don't auto increment
                return true
            }
            if (!autoIncrementing) {
                autoIncrementStart($(this), increment, selling_multiple)
            }
            return false
        })
        .on('keyup', '.field--quantity-spinner .field__input', function () {
            if (autoIncrementing) {
                autoIncrementStop($(this))
                return false
            }
        })

    if (isTouch) {
        $('body')
            .on('touchstart', '.field__spinner-button', function () {
                const $input = $(this).siblings('.field__input')
                $input.focus()
                const selling_multiple = parseInt($input.attr('data-selling_multiple') || 1)
                const $target = $(this)
                let increment = 0
                if ($target.is('.field__spinner-button--down')) {
                    // Left mouse button event on the decrease qty button
                    increment = -1 * selling_multiple
                } else if ($target.is('.field__spinner-button--up')) {
                    // Left mouse button event on the increase qty button
                    increment = selling_multiple
                } else {
                    // Don't auto increment
                    return true
                }

                if (!autoIncrementing) {
                    autoIncrementStart($input, increment, selling_multiple)
                }
                return false
            })
            .on('touchend', '.field__spinner-button', function () {
                if (autoIncrementing) {
                    autoIncrementStop($(this).siblings('.field__input'))
                    return false
                }
            })
    } else {
        $('body')
            .on('mousedown', '.field__spinner-button', function (event) {
                const $input = $(this).siblings('.field__input')
                const selling_multiple = parseInt($input.attr('data-selling_multiple') || 1)
                $input.focus()
                const $target = $(this)
                let increment = 0
                if (event.which === 1 && $target.is('.field__spinner-button--down')) {
                    // Left mouse button event on the decrease qty button
                    increment = -1 * selling_multiple
                } else if (event.which === 1 && $target.is('.field__spinner-button--up')) {
                    // Left mouse button event on the increase qty button
                    increment = selling_multiple
                } else {
                    // Don't auto increment
                    return true
                }

                if (!autoIncrementing) {
                    autoIncrementStart($input, increment, selling_multiple)
                }
                return false
            })
            .on('mouseout mouseup', '.field__spinner-button', function () {
                if (autoIncrementing) {
                    autoIncrementStop($(this).siblings('.field__input'))
                    return false
                }
            })
    }
})()
