export function floatingLabels () {
    // ======================================================================
    // Forms - Show/Hide Floating Labels.
    // ======================================================================

    // On focus or blur, apply or remove the field-active class.
    $('body')
        .on('focus', '.field__input', function () {
            $(this).closest('.field').addClass('field--active')
        })
        .on('blur', '.field__input', function () {
            $(this).closest('.field').removeClass('field--active')
        })

    // On change to an input or textarea, show or hide the floating label.
    $('body').on(
        'keyup keydown blur change cut paste',
        '.field input.field__input, .field textarea.field__input',
        function () {
            if ($(this).val().length != 0) {
                $(this).closest('.field').addClass('field--show-label')
            } else {
                $(this).closest('.field').removeClass('field--show-label')
            }
        }
    )

    // On load, show floating labels for non-empty input or textarea elements.
    $('input.field__input, textarea.field__input', '.field').each(function () {
        if ($(this).val().length != 0) {
            $(this).closest('.field').addClass('field--show-label')
        }
    })
}
