export function handleViewportResize () {
    // ======================================================================
    // When viewport width changes:
    // - Update global isDesktop variable
    // - Trigger custom "viewportWidthResize" event
    // ======================================================================
    let viewportWidth = $(window).width()
    window.isDesktop = window.matchMedia('(min-width: 800px)').matches

    $(window).on('resize', function () {
        if (viewportWidth !== $(window).width()) {
            viewportWidth = $(window).width()
            window.isDesktop = window.matchMedia('(min-width: 800px)').matches
            $(this).trigger('viewportWidthResize')
        }
    })
}
