export function setRadioValue (radio_name, form, value) {
    for (let i = 0; i < form.elements.length; i++) {
        const elmt = form.elements[i]
        if (elmt.name == radio_name && elmt.value == value) {
            elmt.checked = true
            return true
        }
    }
    return false
}
