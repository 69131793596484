export function searchAutosuggest () {

    // ========================================================================
    // Search Autosuggest.
    // ========================================================================
    const $autoSuggest = $('.js-search-autosuggest')
    const $searchInput = $('.js-navbar-search-input')
    const $autoSuggestResults = $('.js-search-autosuggest-results')
    const searchForm = document.querySelector('.js-navbar-search')

    let timeout
    let autoSuggestAJAX
    let isAutoSuggest = false
    $searchInput.on('input', function () {
        // Make an AJAX request to get the results.
        const query = $(this).val()
        isAutoSuggest = false
        if (timeout) {
            // Clear the timeout, if it has been already set.
            clearTimeout(timeout)
        }

        timeout = setTimeout(function () {
            // Make the AJAX request after a 350ms delay to limit the number of requests.
            if (query.length > 0) {
                autoSuggestAJAX = autoSuggestFetch(query)
            } else {
                // Hide the auto-suggest results.
                $autoSuggest.removeClass('is-active')
            }
            // Store the input value so it can be re-inserted later.
            $searchInput.data('query', $searchInput.val())
        }, 350)

    })
        .on('blur', function () {
            // Hide the autosuggest results.
            $autoSuggest.removeClass('is-active')
        })
        .on('focus', function () {
            // Show the autosuggest results, if there are any.

            if ($autoSuggestResults.children().length > 0) {
                $autoSuggest.addClass('is-active')
            }
        })

    $autoSuggestResults.on('mousedown', function (event) {
        // Prevent blur when results are clicked on.
        event.preventDefault()
    })

    $autoSuggestResults.on('click', '.js-autosuggest-result', function (event) {
        // Delegated click handler for selecting an autosuggest result.
        event.preventDefault()
        const value = $(this).data('value')

        $autoSuggestResults.children().removeClass('is-active')
        $(this).addClass('is-active')

        $searchInput
            .val(value)
            .focus()
        isAutoSuggest = true

        if (searchForm.validation) {
            searchForm.validation.submit()
        } else {
            // TODO: Remove this once the search form is universally using the validation module.
            $(searchForm).submit()
        }
    })

    $searchInput.on('keydown', function (event) {
        // Register keydown event on the result.
        const index = $autoSuggestResults.children('.is-active').index()
        const resultCount = $autoSuggestResults.children().length

        switch (event.keyCode) {
            case 27:
                // Escape key pressed. Close autosuggest results.
                $autoSuggest.removeClass('is-active')
                break
            case 13:
                // Enter key pressed - select the result.
                if ($autoSuggest.hasClass('is-active')) {
                    if ($('.js-autosuggest-result.is-active').length == 1) {
                        // There is an active result, so select it.
                        $('.js-autosuggest-result.is-active').trigger('click')
                        event.preventDefault()
                        return false
                    }
                }
                break
            case 40:
                // ↓ key pressed.
                if (resultCount > 0) {
                    // There are autosuggest results.
                    if ($searchInput[0].selectionStart == $searchInput.val().length) {
                        // Caret position is at the end of the input field.
                        event.preventDefault()
                        if (index + 1 != resultCount) {
                            // There is a next result, so select it.
                            $autoSuggestResults.children().removeClass('is-active')
                            const $nextResult = $autoSuggestResults.children().eq(index + 1)
                            $searchInput.val($nextResult.data('value'))
                            $nextResult.addClass('is-active')
                        }
                    }
                }
                break
            case 38:
                // ↑ key pressed.
                if (resultCount > 0) {
                    // There are autosuggest results.
                    if ($searchInput[0].selectionStart == $searchInput.val().length) {
                        // Caret position is at the end of the input field.
                        $autoSuggestResults.children().removeClass('is-active')

                        if (index == 0) {
                            // There is no previous result, reset input field to the original query value.
                            event.preventDefault()
                            $searchInput.val($searchInput.data('query'))
                        } else if (index > 0) {
                            // There is a previous result, so select it.
                            event.preventDefault()
                            const $prevResult = $autoSuggestResults.children().eq(index - 1)
                            if ($prevResult.length > 0) {
                                $searchInput.val($prevResult.data('value'))
                                $prevResult.addClass('is-active')
                            }
                        }
                    }
                }
                break
        }
    })

    $(searchForm).on('submit', function () {
        // Submit the search form.
        if ($searchInput.val().length > 0) {
            // Send the search event to Google Tag Manager.
            window.tagManagerDataLayer = window.tagManagerDataLayer || []
            tagManagerDataLayer.push({
                'event': 'search',
                'event_data': {
                    'value': $searchInput.val(),
                    'autosuggest': isAutoSuggest
                }
            })
        }

        // Hide the autosuggest results after the form is submitted.
        if (autoSuggestAJAX) {
            autoSuggestAJAX.abort()
        }
        if (timeout) {
            clearTimeout(timeout)
        }
        $autoSuggest.removeClass('is-active')
        $autoSuggestResults.children().remove()
    })

    function autoSuggestFetch (query) {
        // Quick Entry form. Make AJAX request to fetch auto-suggest results.
        return $.ajax({
            url: '/tcl/search-autosuggest-1.1.0',
            dataType: 'json',
            data: {
                query: query
            },
            success: function (response) {
                $autoSuggestResults.empty()

                if (response && response.data && response.data.results && response.data.results.length > 0) {
                    // Add auto-suggest results to the page.
                    $.each(response.data.results, function (index, result) {
                        const resultSearchTerm = result.search_term
                        const resultTitleHTML = result.title_html
                        const resultImage = result.image_url
                        const resultDescription = result.description
                        const manufacturerImage = result.manufacturer_image_url
                        const $result = $('.template.js-autosuggest-result').clone()
                        $result.removeClass('template')
                        $result.data('value', resultSearchTerm)
                        $result.find('.js-autosuggest-result-title').html(resultTitleHTML)

                        if (resultImage) {
                            $result.find('.js-autosuggest-result-image').addClass('has-image')
                            $result.find('.js-autosuggest-result-image img').attr('src', resultImage)
                            $result.find('.js-autosuggest-result-image img').addClass('is-product-image')
                        } else {
                            $result.find('.js-autosuggest-result-image img').remove()
                        }

                        if (resultDescription) {
                            $result.find('.js-autosuggest-result-description').html(resultDescription)
                        } else {
                            $result.find('.js-autosuggest-result-description').remove()
                        }

                        if (manufacturerImage) {
                            $result.find('.js-autosuggest-result-aside-image img').attr('src', manufacturerImage)
                        } else {
                            $result.find('.js-autosuggest-result-aside').remove()
                        }

                        $result.appendTo($autoSuggestResults)
                        $autoSuggest.addClass('is-active')
                    })
                } else {
                    $autoSuggest.removeClass('is-active')
                }
            }
        })
    }
}
