// ==========================================================================
// Display Loading Spinner on Submit.
//
// Changes submit button content to a loading spinner on form submission:
//   * Changes submit button content to show message & spinner on redirect.
//   * Restores original content on `valid`, `invalid` and `error` events.
// ==========================================================================
export function displaySpinnerOnSubmit () {

    document.body.addEventListener('submit', function (event) {
        // Delegated event listener for 'submit' on all forms.
        // Updates the content of all submit buttons with a spinner.
        if (event.target.tagName === 'FORM') {
            event.target.querySelectorAll('button[type=submit]').forEach(function (button) {
                if (button.dataset.initHtml === undefined) {
                    // save initial html content of the button
                    button.dataset.initHtml = button.innerHTML
                }
                // Change button content to a spinner
                button.innerHTML = '<div class="ajax-spinner">'
            })
        }
    })

    document.body.addEventListener('validation:redirecting', function (event) {
        // Delegated event listener for 'validation:redirecting' on all forms.
        // Updates the content of all submit buttons with a redirecting message and spinner.
        if (event.target.tagName === 'FORM') {
            event.target.querySelectorAll('button[type=submit]').forEach(function (button) {
                button.innerHTML = 'Redirecting&hellip; <div class="ajax-spinner">'
            })
        }
    })

    const events = ['validation:valid', 'validation:invalid', 'validation:error']
    events.forEach(function (event) {
        document.body.addEventListener(event, function (event) {
            // Delegated event listener for 'validation:valid', 'validation:invalid' and 'validation:error' on all forms.
            // Restores the initial content of all submit buttons.
            if (event.target.tagName === 'FORM') {
                event.target.querySelectorAll('button[type=submit]').forEach(function (button) {
                    if (button.dataset.initHtml !== undefined) {
                        button.innerHTML = button.dataset.initHtml
                    }
                })
            }
        })
    })
}
