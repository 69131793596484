export function productsCookie () {
    // ========================================================================
    // Products Cookie.
    // ========================================================================

    function web_code2product_code (web_code) {
    // Convert web_code to product code.
        return web_code
            .replace(/dot/g, '.')
            .replace(/slash/g, '/')
            .replace(/dash/g, '-')
            .replace(/plus/g, '+')
            .replace(/_DOT_/g, 'DOT')
            .replace(/_SLASH_/g, 'SLASH')
            .replace(/_DASH_/g, 'DASH')
            .replace(/_PLUS_/g, 'PLUS')
    }

    // If this is a new order add a catalogue.
    Cookies.json = false
    if (Cookies.get('order_key') == '') {
        productsCookieUpdate('TLCAT', 1)
    }

    // Update input quantities according to products cookie.
    const products = productsCookieGet()
    $.each(products, function (index, product) {
        $('.js-product-code[value="' + web_code2product_code(product.product_id) + '"]', '.js-add-to-basket')
            .closest('.js-add-to-basket')
            .addClass('is-added')
            .find('.js-qty')
            .val(product.qty)
            .data('previous-value', product.qty)
    })

    function productsCookieGet () {
    // Returns an array of product objects from cookie
        let pair
        Cookies.json = false
        const products = []
        if (Cookies.get('products')) {
            $.each(Cookies.get('products').split('%'), function (index, productID_Qty) {
                pair = productID_Qty.split('~')
                products.push({
                    product_id: pair[0],
                    qty: pair[1],
                })
            })
        }
        return products
    }

    function productsCookieSet (products) {
    // Takes an array of product objects and sets cookie
        const products_array = []
        $.each(products, function (index, product) {
            const productID_Qty = product.product_id + '~' + product.qty
            products_array.push(productID_Qty)
        })
        Cookies.set('products', products_array.join('%'), { expires: 30, path: '/', secure: true })
    }

    function productsCookieUpdate (product_id, qty) {
    // Update/Add entry to product cookie
        const products = productsCookieGet()
        let new_product = true

        // Update corresponding element in products array
        $.each(products, function (index, product) {
            if (product.product_id == product_id) {
                if (qty == 0) {
                    // Remove element from array
                    products.splice(index, 1)
                } else {
                    // Update element in products array
                    products[index].qty = qty
                }
                // If product was found, set a new_product flag and break the loop.
                new_product = false
                return false
            }
        })

        // New Products
        if (new_product && qty != 0) {
            // Add new element in products array
            const new_product = {
                product_id: product_id,
                qty: qty,
            }
            products.push(new_product)
        }

        // Write out products cookie
        productsCookieSet(products)
    }
}
