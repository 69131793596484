export function notificationBanner () {
    // ======================================================================
    // Notification Banner.
    // ======================================================================

    // Timestamp that changes every 10 minutes.
    const cacheTimestamp = Math.round(new Date().getTime() / 1000 / 600)

    $.getJSON(urlSet('/notification-banner.json', '_', cacheTimestamp)).done(function (data) {
        const message_id = data.message_id
        const message = data.message

        // Find last dismissed message id.
        const dismissed_message_id = Cookies.get('notification_dismissed')
        const $notification_banner = $('.js-notification-banner')

        if (dismissed_message_id != message_id && message != '') {
            // Notification not dismissed

            $('.js-notification-banner-message', $notification_banner).html(message)

            // Set cached notification message to avoid cumulative layout shift on load
            if (window.localStorage) {
                localStorage.setItem('notification_banner_message', message)
            }

            $('.js-notification-banner-dismiss', $notification_banner).on('click', function () {
                // On click, hide the notification bar.
                $notification_banner.css('height', $notification_banner.height()).removeClass('is-active')
                $notification_banner.get(0).offsetWidth // force browser to repaint
                $notification_banner.css('height', '0')

                // Set cookie to 'true'
                Cookies.set('notification_dismissed', message_id, { expires: 180, path: '/', secure: true })

                // Clear cached notification message to avoid cumulative layout shift on load
                if (window.localStorage) {
                    localStorage.setItem('notification_banner_message', '')
                }
            })

            $notification_banner.addClass('is-active')
        } else {
            // Notification previously dismissed

            $notification_banner.css('height', '0')

            // Clear cached notifition message to avoid cumulative layout shift on load
            if (window.localStorage) {
                localStorage.setItem('notification_banner_message', '')
            }
        }
    })
}
