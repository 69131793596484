export function navbarSticky () {
    // ======================================================================
    // Show sticky website navbar when user scrolls up.
    // ======================================================================

    if ( $('.js-sticky-navbar').length > 0 ) {
        let lastScrollTop = 0
        const threshold = -2

        window.addEventListener('scroll', function () {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop

            if (scrollTop - lastScrollTop < threshold) {
                // Scrolled up past the threshold - show the navbar.
                $('.js-sticky-navbar').addClass('is-fixed')
            }

            if (scrollTop > lastScrollTop || scrollTop <= 0 || $(':target').hasClass('anchor-target')) {
                // Scrolled down, at the top of page, or visiting an information page anchor - hide the navbar.
                $('.js-sticky-navbar').removeClass('is-fixed')
            }

            // For mobile negative scrolling.
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
        }, false)
    }

}
