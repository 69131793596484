export function getCookie (name) {
    const pairs = document.cookie.split('; ')
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=')
        if (pair[0] == name || pair[0] == escape(name)) {
            return unescape(pair[1])
        }
    }
    return null // if no match return null
}

export function deleteCookie (name) {
    // Set cookie expiry yesterday
    setCookie(name, null, -1)
    if (getCookie(name)) {
        return false
    }
    return true

}

export function setCookie (name, value, days, secure) {
    const today = new Date()
    let newCookie = new String()
    const deliminator = new String('; ')
    const expiry = new Date(today.getTime() + days * 24 * 60 * 60 * 1000) // plus days
    newCookie = escape(name) + '=' + escape(value) + deliminator + 'path=/'
    if (days) {
        newCookie += deliminator + 'expires=' + expiry.toGMTString()
    }
    if (secure === true) {
        newCookie += deliminator + 'secure'
    }
    document.cookie = newCookie
    if (getCookie(name) == value) {
        return true
    }
    return false

}
