export function navbarMenus () {

    // ======================================================================
    // All Categories A-Z dropdown menu (desktop-view).
    // ======================================================================

    $('.js-category-menu-control').on('click', function (event) {
        const $menu = $('.js-category-menu')
        if ($(this).hasClass('is-active')) {
            // Close the dropdown menu.
            $(this).removeClass('is-active')
            $menu.removeClass('is-active')
        } else {
            // Open the dropdown menu.
            $('.navbar-account-menu').removeClass('is-active')

            // Insert HTML for the category menu.
            if ($menu.hasClass('is-loading')) {
                generateCategoryMenu($menu, function () {
                    $menu.removeClass('is-loading')
                })
            }

            $(this).addClass('is-active')
            $menu.addClass('is-active')
        }
        event.stopPropagation()
    })

    $(document).on('keyup', function (event) {
    // When Escape key is pressed, close active menus.
        if (isDesktop && event.keyCode == 27) {
            $('.navbar-account-menu, .js-category-menu-control, .js-category-menu').removeClass('is-active').blur()
        }
    })

    $('body').on('click', function () {
    // When clicking outside dropdown, close active menus.
        if (isDesktop) {
            $('.js-category-menu-control, .js-category-menu').removeClass('is-active')
        }
    })
}

export function generateCategoryMenu ($element, callback) {
    // Generate & insert HTML for the A-Z category menu into the given element.

    // Fetch data for the category menu.
    $.ajax({
        url: '/category-menu-1.0.0.json',
        cache: true,
        async: true,
        dataType: 'json',
        success: function (data) {
            const $templateCategoryMenuItem = $('.template.js-template-category-menu-item')
            const $container = $element.find('.js-category-menu-container')
            $container.empty()

            $.each(data.categories, function (index, category) {
                const $categoryMenuItem = $templateCategoryMenuItem.clone().removeClass('template')
                $categoryMenuItem.attr('href', category.url)
                $categoryMenuItem.find('.js-category-menu-item-img').attr({
                    'src': category.image,
                    'alt': category.title
                })
                $categoryMenuItem.find('.js-category-menu-item-title').text(category.title)
                $container.append($categoryMenuItem)
            })

            return callback()
        }
    })
}
