export function priceDrops () {
    // Price Drops

    function formatPromoTimeRemaining (secondsLeft) {
        let timeRemaining

        // do some time calculations
        const days = parseInt(secondsLeft / 86400)
        secondsLeft = secondsLeft % 86400

        const hours = parseInt(secondsLeft / 3600)
        secondsLeft = secondsLeft % 3600

        const minutes = parseInt(secondsLeft / 60)
        const seconds = parseInt(secondsLeft % 60)

        // format countdown string + set tag value
        if (days > 1) {
            // show days only
            timeRemaining = days + ' days'
        } else if (days == 1) {
            // show days only
            timeRemaining = days + ' day'
        } else if (hours >= 10) {
            // show hours and minutes
            timeRemaining = hours + 'h, ' + minutes + 'm'
        } else if (hours > 0) {
            // show hours, minutes and seconds
            timeRemaining = hours + 'h, ' + minutes + 'm, ' + seconds + 's'
        } else if (minutes > 0) {
            // show minutes and seconds
            timeRemaining = minutes + 'm, ' + seconds + 's'
        } else if (seconds > 0) {
            // show seconds
            timeRemaining = seconds + 's'
        } else {
            timeRemaining = '0s'
        }

        return timeRemaining
    }

    if ($('.js-promo-prices').length) {
    // There are price drops scheduled

        // Initialise time remaining for price drops
        $('.js-promo-prices').each(function () {
            const $promoPrices = $(this)
            const promoStartEpoch = $promoPrices.data('promo-start-epoch')
            const promoEndEpoch = $promoPrices.data('promo-end-epoch')
            const currentEpoch = Math.floor(new Date().getTime() / 1000)

            if (promoStartEpoch != undefined && promoEndEpoch != undefined && currentEpoch >= promoStartEpoch) {
                // Promo has started
                const timeRemaining = formatPromoTimeRemaining(promoEndEpoch - currentEpoch)

                // update time remaining
                $('.js-time-remaining', $promoPrices).text(timeRemaining)
            }
        })

        // Set interval to update time remaining for price drops every second
        setInterval(function () {
            $('.js-promo-prices').each(function () {
                const $promoPrices = $(this)
                const promoStartEpoch = $promoPrices.data('promo-start-epoch')
                const promoEndEpoch = $promoPrices.data('promo-end-epoch')
                const currentEpoch = Math.floor(new Date().getTime() / 1000)

                if (promoStartEpoch != undefined && promoEndEpoch != undefined && currentEpoch >= promoStartEpoch) {
                    // Promo is active
                    const timeRemaining = formatPromoTimeRemaining(promoEndEpoch - currentEpoch)

                    // update time remaining
                    $('.js-time-remaining', $promoPrices).text(timeRemaining)
                }
            })
        }, 1000)

        // Show promo prices instead of standard prices if promo is active
        $('.js-promo-prices').each(function () {
            const $promoPrices = $(this)
            const promoStartEpoch = $promoPrices.data('promo-start-epoch')
            const promoEndEpoch = $promoPrices.data('promo-end-epoch')
            const currentEpoch = Math.floor(new Date().getTime() / 1000)

            if (
                promoStartEpoch != undefined &&
        promoEndEpoch != undefined &&
        currentEpoch >= promoStartEpoch &&
        currentEpoch < promoEndEpoch
            ) {
                // Promo is active - hide standard prices and show promo prices
                $promoPrices.siblings('.js-standard-prices').hide()
                $promoPrices.show()
            }
        })
    }
}
