import { environment } from './modules/core/environment.mjs'
import { getCookie, setCookie, deleteCookie } from './modules/core/cookie.mjs'
import { popUpLink } from './modules/core/popUpLink.mjs'
import { checkReferrer, trackingKeyword, trackingSource, trackingCampaign, trackingMedia, referralSearchTerms, referralDomain, } from './modules/core/checkReferrer.mjs'
import { getOptionValue } from './modules/core/getOptionValue.mjs'
import { setOptionValue } from './modules/core/setOptionValue.mjs'
import { getRadioValue } from './modules/core/getRadioValue.mjs'
import { setRadioValue } from './modules/core/setRadioValue.mjs'
import { urlSet } from './modules/core/urlSet.mjs'
import { urlDataSet } from './modules/core/urlDataSet.mjs'
import { urlGet } from './modules/core/urlGet.mjs'
import { urlDataGet } from './modules/core/urlDataGet.mjs'
import { tradeDoubler } from './modules/core/tradeDoubler.mjs'
import { handleViewportResize } from './modules/core/handleViewportResize.mjs'
import { navbarSearch } from './modules/core/navbarSearch.mjs'
import { mobileNav } from './modules/core/mobileNav.mjs'
import { navbarMenus } from './modules/core/navbarMenus.mjs'
import { navbarSticky } from './modules/core/navbarSticky.mjs'
import { searchAutosuggest } from './modules/core/searchAutosuggest.mjs'
import { navbarBasketUpdate } from './modules/core/navbarBasketUpdate.mjs'
import { handlePluginsReady } from './modules/core/handlePluginsReady.mjs'
import { notificationBanner } from './modules/core/notificationBanner.mjs'

// To review (possibly can be moved out of core into page-specific files):
import { floatingLabels } from './modules/core/floatingLabels.mjs'
import { displaySpinnerOnSubmit } from './modules/core/displaySpinnerOnSubmit.mjs'
import { setErrorStateOnInvalidResponse } from './modules/core/setErrorStateOnInvalidResponse.mjs'
import { productsCookie } from './modules/core/productsCookie.mjs'
import { addToBasket } from './modules/core/addToBasket.mjs'
import { priceDrops } from './modules/core/priceDrops.mjs'
import { videoLightbox } from './modules/core/videoLightbox.mjs'
import { jumpto } from './modules/core/jumpto.mjs'
import { vatPreference } from './modules/core/vatPreference.mjs'
import { quantitySpinner } from './modules/core/quantitySpinner.mjs'
import { radioGroup } from './modules/core/radioGroup.mjs'

window.getCookie = getCookie
window.setCookie = setCookie
window.deleteCookie = deleteCookie
window.popUpLink = popUpLink
window.checkReferrer = checkReferrer
window.getOptionValue = getOptionValue
window.setOptionValue = setOptionValue
window.getRadioValue = getRadioValue
window.setRadioValue = setRadioValue
window.urlSet = urlSet // There are duplicate global urlSet & urlDataSet functions declared here and in qcode-ui.js
window.urlDataSet = urlDataSet
window.urlGet = urlGet
window.urlDataGet = urlDataGet
window.trackingKeyword = trackingKeyword
window.trackingSource = trackingSource
window.trackingCampaign = trackingCampaign
window.trackingMedia = trackingMedia
window.referralSearchTerms = referralSearchTerms
window.referralDomain = referralDomain
window.tradeDoubler = tradeDoubler

environment()
vatPreference()

// Tracking
checkReferrer()
tradeDoubler()
handleViewportResize()
mobileNav()
navbarMenus()
navbarSticky()

const searchForm = document.querySelector('.js-navbar-search')
if (searchForm) {
    navbarSearch()
    searchAutosuggest()
}

const navbarBasket = document.querySelector('.navbar-basket')
if (navbarBasket) {
    navbarBasketUpdate(navbarBasket)
}

productsCookie()
addToBasket()
window.jumpto = jumpto
videoLightbox()
handlePluginsReady()
priceDrops()
notificationBanner()
floatingLabels()
displaySpinnerOnSubmit()
setErrorStateOnInvalidResponse()

// Remember last shopping page for Continue Shopping Button
const regExp = new RegExp('/(Main_Index|Products|Manufacturers|Type)/')
if (regExp.exec(window.location.pathname)) {
    setCookie('continue_shopping_url', window.location.href, undefined, true)
}

// Check if customer_key set in url
if (urlGet(document.location.href, 'customer_key') != null) {
    const customer_key = urlGet(document.location.href, 'customer_key')
    setCookie('customer_key', customer_key, 30, true)
}

quantitySpinner
radioGroup
