export function urlDataSet (data, name, value) {
    const list = new Array()
    let a = new Array()
    let b = new Array()
    const c = new Array()

    if (data != '') {
        a = data.split('&')
    }
    for (let i = 0; i < a.length; i++) {
        b = a[i].split('=')
        const n = decodeURIComponent(b[0].replace(/\+/g, ' '))
        const v = decodeURIComponent(b[1].replace(/\+/g, ' '))
        c[n] = v
    }
    c[name] = value
    for (const key in c) {
        list.push(encodeURIComponent(key) + '=' + encodeURIComponent(c[key]))
    }

    data = list.join('&')
    return data
}
