import { generateCategoryMenu } from './navbarMenus.mjs'

export function mobileNav () {
    // ======================================================================
    // Mobile navigation.
    // ======================================================================
    const $mobileMenu = $('.js-mobile-menu')
    const $mobileMenuButton = $('.js-mobile-menu-button')
    let eventType
    let dragging = false
    // Use touchend event if available instead of click.
    const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints
    if (isTouch) {
        eventType = 'touchend'
    } else {
        eventType = 'click'
    }

    function showMenu () {
    // Show mobile navigation menu
        $mobileMenu.removeClass('is-hidden')
        $mobileMenu.get(0).offsetHeight
        $mobileMenu.addClass('is-active')
        $('[data-menu-id="home"]', $mobileMenu).addClass('is-active')
        $('body').addClass('no-scroll')
    }

    function hideMenu () {
    // Hide mobile navigation menu.
        $mobileMenu.removeClass('is-active')
        $('.js-mobile-sub-menu', $mobileMenu).removeClass('is-active')
        $('body').removeClass('no-scroll')
        $mobileMenu.one('transitionend webkitTransitionEnd oTransitionEnd', function () {
            $(this).addClass('is-hidden')
        })
    }

    $mobileMenuButton.on(eventType, function () {
        showMenu()
    })

    $('.js-mobile-menu-close-control', $mobileMenu).on(eventType, function () {
        hideMenu()
    })


    // Sets touchmove event listeners to passive (see https://web.dev/uses-passive-event-listeners/)
    jQuery.event.special.touchmove = {
        setup: function (_, ns, handle) {
            this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') })
        }
    }

    // Mobile menu item delegated event handlers.
    $mobileMenu
        .on('touchmove', '.js-mobile-menu-item', function () {
            dragging = true
        })
        .on(eventType, '.js-mobile-menu-item', function () {
            if (!dragging) {
                const menuId = $(this).data('show-menu')
                // Switch menus for menu items with a sub-menu/target data-attribute.

                // A-Z category menu.
                if (menuId == 'all-categories') {
                    // Insert HTML for the category menu.
                    const $mobileCategoryMenu = $('.js-mobile-menu-categories')
                    if ($mobileCategoryMenu.hasClass('is-loading')) {
                        generateCategoryMenu($mobileCategoryMenu, function () {
                            $mobileCategoryMenu.removeClass('is-loading')
                        })
                    }
                }

                $('.js-mobile-sub-menu', $mobileMenu)
                    .removeClass('is-active')
                    .filter('[data-menu-id="' + menuId + '"]')
                    .addClass('is-active')
            }
            dragging = false
        })

    // When Escape key is pressed close active menu.
    $(document).on('keyup', function (event) {
        if (!isDesktop && event.keyCode == 27) {
            hideMenu()
        }
    })
}
