export function videoLightbox () {
    // ======================================================================
    // Video Lightbox
    // ======================================================================
    function autoSizeVideo (aspectRatio) {
        // Apply height / width values to video constrained to a given aspect ratio

        const videoHeight = $('.modal__video').width() / aspectRatio
        const maxHeight = $(window).height() - 80

        if (videoHeight < maxHeight) {
            // in most cases, set the height via calculation using width + aspect ratio...
            $('.modal__video').height(videoHeight).width('')
        }
        else {
            // If video height exceeds window height, set according height (with a limit) & recalculate width
            $('.modal__video')
                .height(maxHeight)
                .width($(window).height() * aspectRatio)
        }
    }

    if ($('.button--modal-video, .js-modal-video-control').length > 0) {
        // HTML for the video container and close button...
        const $modalVideo = $('<span class="modal">')
            .append(
                $('<div class="modal__video">')
                    .append(
                        $('<div class="modal__close-control">')
                            .append('Close <div class="icon icon--cross">')
                    )
            )

        $modalVideo.on('click touchend', function () {
            // Remove modal on click/tap
            $('iframe', $modalVideo).remove()
            $modalVideo.detach()
            $(window).off('resize.modalVideo')
            return false
        })

        $('.button--modal-video, .js-modal-video-control').on('click', function () {
            // click event for the "Watch Video" button
            let type = $(this).data('type')
            if (type == undefined) {
                type = 'youtube'
            }

            let aspectRatio = $(this).data('aspect-ratio')
            if (type == 'youtube') {
                if (aspectRatio == undefined) {
                    // Default to 16:9
                    aspectRatio = 16 / 9
                }
                const id = $(this).data('id')
                const $iframe = $('<iframe src="https://www.youtube.com/embed/' + id + '?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen="">')
                $($iframe, $modalVideo).addClass('loading')
                $('.modal__video', $modalVideo).append($iframe)
                $('body').append($modalVideo)

                $('iframe', $modalVideo).one('load', function () {
                    $(this)
                        .removeClass('loading')
                        .addClass('loaded')
                })
            }

            autoSizeVideo(aspectRatio)

            $(window).on('resize.modalVideo', function () {
                autoSizeVideo(aspectRatio)
            })

        })
    }
}
