import { urlDataSet } from './urlDataSet'

export function urlSet (url, name, value) {
    const re = /([^?#]+)(?:\?([^#]*))?(#.*)?/
    re.exec(url)
    const path = RegExp.$1
    const queryString = RegExp.$2
    const fragment = RegExp.$3
    const newURL = path + '?' + urlDataSet(queryString, name, value) + fragment
    return newURL
}
