import Validation from 'https://js.qcode.co.uk/qcode-ui-modules/v1.0.25/Validation.js'

// ======================================================================
// Basket Update AJAX.
//
// Called on page load and triggered when the user adds or removes an
// item from the basket.
// ======================================================================
import escapeHTML from '../escapeHTML.mjs'

function navbarBasketUpdate () {
    let basketUpdateAJAX

    $('body').on('basket-update', function () {

        if (typeof basketUpdateAJAX != 'undefined') {
            // Cancel existing AJAX call.
            basketUpdateAJAX.abort()
        }

        basketUpdateAJAX = $.ajax({
            url: '/tcl/basket-update-1.1.0.json',
            cache: false,
            async: true,
            dataType: 'json',
            success: function (data) {

                // Update basket info in navbar.
                displayBasket(data.productCount, data.total)

                // Update account menu in navbar.
                if (data.signedIn) {
                    displaySignedIn(data.customerName, data.orders)
                } else {
                    displaySignedOut()
                }
            }
        })
    })

    // Trigger 'basket-update' event on page load.
    $('body').trigger('basket-update')
}

function displayBasket (productCount, total) {
    // Update the basket count and total in the navbar.
    const basket = document.querySelector('.js-navbar-basket')
    const basketTotal = basket.querySelector('.js-navbar-basket-total')
    const basketProductCount = basket.querySelector('.js-navbar-basket-count')
    basketTotal.innerHTML = total
    basketProductCount.innerHTML = productCount
    basket.classList.remove('is-loading')
}

function displaySignedIn (customerName, orders) {

    // Initialise the 'My Account' menu for signed-in users.
    const accountMenu = document.querySelector('.js-navbar-account')
    const nextURL = window.location.href
    const signOutNextURL = accountMenu.dataset.signOutNextUrl || nextURL

    // Display user's first name in navbar.
    const accountMenuName = document.querySelector('.js-navbar-account-name')
    accountMenuName.innerHTML = customerName

    // Display user's account menu.
    accountMenu.classList.add('is-signed-in')
    accountMenu.setAttribute('tabindex', '0')

    // Display the in-progress orders menu item, if any.
    if (parseInt(orders.inProgress) > 0) {
        const inProgressOrdersIndicator = document.querySelector('.js-navbar-account-in-progress-orders')
        inProgressOrdersIndicator.classList.remove('is-hidden')
        inProgressOrdersIndicator.querySelector('.badge').innerHTML = orders.inProgress
    }

    // Insert the 'Open Orders' menu count.
    const openOrdersIndicator = document.querySelector('.js-navbar-account-open-orders')
    openOrdersIndicator.querySelector('.badge').innerHTML = orders.open

    // Update signed-in welcome message.
    const mobileMenuWelcome = document.querySelector('.js-mobile-menu-welcome')
    mobileMenuWelcome.innerHTML = `Hello <strong>${escapeHTML(customerName)}</strong>.`

    // Mobile navigation menu, show signed-in menu items.
    const mobileMenuSignedInControls = document.querySelector('.js-mobile-menu-signed-in-controls')
    mobileMenuSignedInControls.classList.remove('is-hidden')

    const formSignOutDesktop = document.querySelector('.js-navbar-sign-out-form')
    formSignOutDesktop.querySelector('input[name="next_url"]').value = signOutNextURL
    const formSignOutMobileContainer = document.querySelector('.js-mobile-menu-sign-out')

    if (
        formSignOutDesktop &&
        !formSignOutDesktop.validation &&
        !formSignOutMobileContainer.querySelector('form')
    ) {
        // Initialise mobile and desktop 'Sign Out' forms in navigation menus.
        formSignOutDesktop.validation = new Validation(formSignOutDesktop, {
            messages: {
                target: document.querySelector('.navbar'),
                insert: 'after'
            }
        })
        // Set up 'Sign Out' form in mobile navigation menu.
        const formSignOutMobile = document.createElement('form')
        formSignOutMobile.classList.add('form-action-link', 'navbar-mobile-menu__item')
        formSignOutMobile.setAttribute('action', '/tcl/user/sign-out')
        formSignOutMobile.setAttribute('method', 'POST')
        formSignOutMobile.innerHTML =
                `<input class="form-action-link__input" type="submit" value="Sign Out" disabled data-enable-on-load>
                 <input type="hidden" name="next_url" value="${escapeHTML(signOutNextURL)}">`
        formSignOutMobile.validation = new Validation(formSignOutMobile, {
            messages: {
                target: document.querySelector('.navbar-mobile-menu__body')
            }
        })
        formSignOutMobileContainer.appendChild(formSignOutMobile)
    }
    accountMenu.classList.remove('is-loading')
}

function displaySignedOut () {
    // Display the 'Sign In' and 'Register' links for guest users in the navbar.
    const accountMenu = document.querySelector('.js-navbar-account')
    const nextURL = window.location.href
    const signInNextURL = accountMenu.dataset.signInNextUrl || nextURL
    const signInURL = urlSet('/tcl/user/sign-in', 'next_url', signInNextURL)
    const registerNextURL = accountMenu.dataset.registerNextUrl || nextURL
    const registerURL = urlSet('/tcl/user/register', 'next_url', registerNextURL)

    const signInLink = document.querySelector('.js-navbar-sign-in-link')
    signInLink.setAttribute('href', signInURL)
    const registerLink = document.querySelector('.js-navbar-register-link')
    registerLink.setAttribute('href', registerURL)

    accountMenu.classList.remove('is-signed-in')
    accountMenu.classList.remove('is-loading')

    // Mobile navigation menu guest welcome message.
    const mobileMenuWelcome = document.querySelector('.js-mobile-menu-welcome')
    mobileMenuWelcome.innerHTML = `Hello. <a href="${escapeHTML(signInURL)}">Sign In</a> or <a href="${escapeHTML(registerURL)}">Register</a>.`

    //  Not signed in, so hide My Account-related menu items.
    const signedInControls = document.querySelector('.js-mobile-menu-signed-in-controls')
    signedInControls.classList.add('is-hidden')
}

export { navbarBasketUpdate }
