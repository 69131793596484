export function navbarSearch () {
    // ======================================================================
    // For small mobile screens, shorten the search placeholder text.
    // ======================================================================
    const $searchForm = $('.js-navbar-search')
    const $searchInput = $('.js-navbar-search-input')

    function updatePlaceholder () {
    // use short placeholder for viewports under 400px, otherwise use long placeholder
        if (window.matchMedia('(max-width: 400px)').matches) {
            $searchInput.attr('placeholder', $searchInput.data('placeholder-short'))
        } else {
            $searchInput.attr('placeholder', $searchInput.data('placeholder-long'))
        }
    }

    $(window).on('viewportWidthResize', function () {
        updatePlaceholder()
    })

    updatePlaceholder()


    // ======================================================================
    // 'Populated' state.
    // ======================================================================

    $searchInput.on('input', function () {
    // On input, set the populated state.
        setIsPopulatedState()
    })

    function setIsPopulatedState () {
    // If search input field is not empty, show the form's populated state.
        if ($searchInput.val() !== '') {
            $searchForm.addClass('is-populated')
        } else {
            $searchForm.removeClass('is-populated')
        }
    }

    setIsPopulatedState()

    // ======================================================================
    // Autocomplete off to prevent default browser showing previous searches.
    //
    // Remove `autocomplete="off"` before page navigation to work around
    // Safari bfcache issue where `autocomplete="off"` prevents form fields
    // from being saved/restored.
    // ======================================================================

    window.addEventListener('visibilitychange', () => {
        const searchInput = document.querySelector('.js-navbar-search-input')
        if (document.visibilityState === 'visible') {
            searchInput.autocomplete = 'off'
        } else if (document.visibilityState === 'hidden') {
            searchInput.removeAttribute('autocomplete')
        }
    })
}
