export function handlePluginsReady () {
    // ======================================================================
    // pluginsReady event handler
    // ======================================================================
    $('body').on('pluginsReady', function () {
        // enable field components that have been temporarily disabled until the pluginsReady event is fired.
        $('.field--enable-on-pluginsready :input[disabled]').prop('disabled', false)
        $('.field--enable-on-pluginsready').removeClass('field--disabled').removeClass('field--enable-on-pluginsready')

        $(this).removeClass('plugins-loading')
    })
}
