// *****************************************************************************
// Radio Group
// *****************************************************************************

export const radioGroup = (function () {

    // Initialise any checked radio groups.
    $('.option__input:checked').siblings('.option__content').addClass('option__content--active')
    $('.option__input:checked').closest('.radio-group__option').addClass('is-active')

    $('.option__input').on('change', function () {
    // Add/remove active class to radio group options.

        const transitionEndCallback = function () {
            // Trigger transitionEnd event so we know show/hide animation has finished.
            $(this).trigger('transitionEnd.radioGroup')
        }

        // Add active class to the option that is selected.
        $(this).siblings('.option__content').addClass('option__content--active', 300, transitionEndCallback)
        $(this).closest('.radio-group__option').addClass('is-active')

        // Remove active class from all other options.
        $('.option__input[name="' + $(this).attr('name') + '"]')
            .not($(this))
            .each(function () {
                $(this).siblings('.option__content').removeClass('option__content--active', 300, transitionEndCallback)
                $(this).closest('.radio-group__option').removeClass('is-active')
            })
    })
})()
