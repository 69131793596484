// ==========================================================================
// Forms - Remove .field--error classes on submit
//       - Add .field--error classes on valid and invalid events for invalid fields
// ==========================================================================

export function setErrorStateOnInvalidResponse () {

    document.body.addEventListener('validation:invalid', function (event) {
        // Delegated event listener for 'validation:invalid' on all forms.
        // Adds the 'field--error' class to surrounding '.field' elements of
        // each invalid input.
        if (
            event.target.tagName === 'FORM' &&
            event.detail.response &&
            event.detail.response.record
        ) {

            Object.entries(event.detail.response.record).forEach(function ([key, value]) {
                if (value && value.valid === false) {
                    const input = event.target.querySelector(`[name=${key}]`)
                    const field = input ? input.closest('.field') : null
                    if (field) {
                        field.classList.add('field--error')
                    }
                }
            })
        }
    })

    document.body.addEventListener('submit', function (event) {
        // Delegated event listener for 'submit' on all forms. Removes the
        // 'field--error' class from all '.field' elements.
        if (event.target.tagName === 'FORM') {
            event.target.querySelectorAll('.field--error').forEach(function (field) {
                field.classList.remove('field--error')
            })
        }
    })
}
