import { urlGet } from './urlGet'
import { getCookie, setCookie } from './cookie'

export function vatPreference () {
    // ======================================================================
    // Inc/Ex VAT Toggle
    // ======================================================================
    const source = urlGet(document.location.href, 'source')
    let vat_preference = getCookie('vat_preference') // "inc_vat", "ex_vat" or null.
    if (vat_preference == 'inc_vat') {
    // Cookie Found - Show prices Inclusive of VAT
        showIncVatPrices()
        $('.js-vat-select', '.js-vat-modal').val('inc_vat')
    } else if (vat_preference == 'ex_vat') {
    // Cookie Found - Show Prices Exlusive of VAT
        showExVatPrices()
        $('.js-vat-select', '.js-vat-modal').val('ex_vat')
    } else if (source == 'froogle' || source == 'adwords') {
    // Cookie Not Found & Traffic Froogle or Adwords - Default to Prices Inclusive of VAT
        showIncVatPrices()

        if (vat_preference != 'inc_vat') {
            setCookie('vat_preference', 'inc_vat', undefined, true)
            // Trigger basket-update event
            $('body').trigger('basket-update')
        }
        $('.js-vat-select', '.js-vat-modal').val('inc_vat')
    } else {
    // Cookie Not Found & Traffic not from Froogle or Adwords - Default to Prices Exclusive of VAT
        showExVatPrices()

        if (vat_preference != 'ex_vat') {
            setCookie('vat_preference', 'ex_vat', undefined, true)
            // Trigger basket-update event
            $('body').trigger('basket-update')
        }
        $('.js-vat-select', '.js-vat-modal').val('ex_vat')
    }

    // Show VAT Preference modal dialog on click.
    $('body').on('click', '.js-show-vat-modal', function () {
        $('.js-vat-modal').modal('open')
    })

    // Save & close modal dialog on clicking 'Save' button.
    $('.js-save-modal', '.js-vat-modal').on('click', function () {
        vat_preference = $('.js-vat-select').val()
        if (vat_preference === 'inc_vat') {
            showIncVatPrices()
        } else {
            showExVatPrices()
        }
        setCookie('vat_preference', vat_preference, 3650, true)
        $('body').trigger('basket-update')
        $('.js-vat-modal').modal('close')
    })

    function showExVatPrices () {
    // Show VAT-Exclusive prices in price-breaks.
        $('body').removeClass('is-inc-vat').addClass('is-ex-vat')
    }

    function showIncVatPrices () {
    // Show VAT-Inclusive prices in price-breaks.
        $('body').removeClass('is-ex-vat').addClass('is-inc-vat')
    }

}
