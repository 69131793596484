import { setCookie, getCookie } from './cookie'
import { urlGet } from './urlGet'

function consentCookieExists () {
    const values = getCookie('consent_mode')

    if (values !== null && values.length > 0) {
        return true
    }

    return false
}

function consentCookieGet () {
    const values = getCookie('consent_mode')
    return JSON.parse(values)
}

function isConsentGranted (consentType) {
    if (!consentCookieExists()) {
        return false
    }
    const consent = consentCookieGet()
    if (consent[consentType]) {
        return true
    }
    return false

}

export function checkReferrer () {

    if (!isConsentGranted('analytics')) {
        // Skip referrer tracking if user has not consented to analytics cookies.
        document.addEventListener('consent-mode-update', function () {
            checkReferrer()
        }, { once: true })
        return
    }

    const source = trackingSource()
    const media = trackingMedia()
    const campaign = trackingCampaign()
    const kw = trackingKeyword()
    const domain = referralDomain()
    const term = referralSearchTerms()

    if (source || domain) {
        const now = new Date()
        const datetime =
      now.getUTCFullYear() +
      '-' +
      parseInt(now.getUTCMonth() + 1) +
      '-' +
      now.getUTCDate() +
      ' ' +
      now.getUTCHours() +
      ':' +
      now.getUTCMinutes() +
      ':' +
      now.getUTCSeconds()
        let tracking_history = []
        if (getCookie('tracking_history')) {
            tracking_history = JSON.parse(getCookie('tracking_history'))
        }
        const track = new Object()
        track.datetime = datetime
        if (source) {
            track.source = source
        }
        if (campaign) {
            track.campaign = campaign
        }
        if (kw) {
            track.kw = kw
        }
        if (media) {
            track.media = media
        }
        if (domain) {
            track.domain = domain
        }
        if (term) {
            track.term = term
        }

        while (tracking_history.length > 10 || JSON.stringify(tracking_history).length > 1024) {
            tracking_history.shift()
        }
        tracking_history.push(track)
        setCookie('tracking_history', JSON.stringify(tracking_history), 365, true)
    }
}


export function trackingCampaign () {
    const url = document.location.href
    const varnames = ['utm_campaign', 'campaign']

    for (let i = 0; i < varnames.length; i++) {
        if (urlGet(url, varnames[i]) != null) {
            return urlGet(url, varnames[i])
        }
    }
    return null
}


export function trackingKeyword () {
    const url = document.location.href
    const varnames = ['kw', 'utm_term', 'OVRAW']
    for (let i = 0; i < varnames.length; i++) {
        if (urlGet(url, varnames[i]) != null) {
            return urlGet(url, varnames[i])
        }
    }
    return null
}


export function trackingMedia () {
    const url = document.referrer
    const varnames = ['utm_media', 'media', 'utm_medium', 'medium']
    for (let i = 0; i < varnames.length; i++) {
        if (urlGet(url, varnames[i]) != null) {
            return urlGet(url, varnames[i])
        }
    }
    return null
}

export function trackingSource () {
    const url = document.location.href
    const varnames = ['utm_source', 'source']
    for (let i = 0; i < varnames.length; i++) {
        if (urlGet(url, varnames[i]) != null) {
            return urlGet(url, varnames[i])
        }
    }
    return null
}


export function referralDomain () {
    const url = document.referrer
    const re = /([^:]+):\/\/([^:/]+)(:(\d+))?([^?]*)([?](.*))?/
    if (re.exec(url)) {
        const domain = RegExp.$2
        if (domain != document.location.hostname) {
            return domain
        }
    }
    return null
}


export function referralSearchTerms () {
    const url = document.referrer
    const varnames = ['q', 'query', 'qry', 'qt', 'ask', 'search', 'keyword', 'word', 'p']
    for (let i = 0; i < varnames.length; i++) {
        if (urlGet(url, varnames[i]) != null) {
            return urlGet(url, varnames[i])
        }
    }
    return null
}
