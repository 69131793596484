export function environment () {
    const hostname = window.location.hostname
    let environment = 'DEV'
    switch (hostname) {
        case 'www.tlc-direct.co.uk':
            environment = 'LIVE'
            break
        case 'www.testing.tlc-direct.co.uk':
            environment = 'TESTING'
            break
        case 'www.webdev.tlc-direct.co.uk':
            environment = 'WEBDEV'
            break
    }

    // Central store for credentials
    const credentials = {
        DEV: {
            PAYPAL_CLIENT_ID: 'AUcB1AiKzRxT6u4_ndneE0Rwjg30p2UhGUyLM6TNGXDsDgl2GjmbIgDBDhtI9VQHWLodgnW_AGIhkLHe',
            CYBERSOURCE_ACTION_URL: 'https://testsecureacceptance.cybersource.com/silent/pay',
            GOOGLE_SEARCH_CX: '009480127100218919096:i9fsiuzkjcs'

        },
        LIVE: {
            PAYPAL_CLIENT_ID: 'AYcnlFHzWST5gvktIQ5zkGf_L1E4RAh_jGsBO3pYPFpQokcajijZ8SgV_8nOfbwACPeKR5YOk7pnAuS6',
            CYBERSOURCE_ACTION_URL: 'https://secureacceptance.cybersource.com/silent/pay',
            GOOGLE_SEARCH_CX: '017216665653597683715:cpr1ts3w7o0'
        }
    }

    function getCredential (name) {
        // Return the credential for the current environment.
        switch (getEnvironment()) {
            case 'DEV':
            case 'WEBDEV':
            case 'TESTING':
                return credentials.DEV[name]
            case 'LIVE':
                return credentials.LIVE[name]
        }
    }

    function getEnvironment () {
        // Return the current environment.
        return environment
    }

    window.getEnvironment = getEnvironment
    window.getCredential = getCredential
}
