export function urlDataGet (queryString, key) {
    // Gets the value of a key from a query string
    let urlData = []
    let urlParameter = []

    if (!queryString || typeof queryString !== 'string') {
        return null
    }

    urlData = queryString.split('&')

    for (let i = 0; i < urlData.length; i++) {
    // Split the key and value
        urlParameter = urlData[i].split('=')

        if (key == decodeURIComponent(urlParameter[0].replace(/\+/g, ' '))) {
            if (typeof urlParameter[1] !== 'undefined') {
                return decodeURIComponent(urlParameter[1].replace(/\+/g, ' '))
            }
            return null

        }
    }
    // Return null if the key is not found
    return null
}
