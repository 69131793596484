/**
 * escapeHTML
 *
 * @description Escape HTML entities in a string.
 * @param {string} string - String with HTML entities
 * @returns {string} - String with HTML entities escaped
 *
 * @example
 * const string = 'Hello & World'
 * const escapedString = escapeHTML(string)
 * // => 'Hello &amp; World'
 */

function escapeHTML (string) {
    return String(string)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
}

export default escapeHTML
