import Validation from 'https://js.qcode.co.uk/qcode-ui-modules/v1.0.25/Validation.js'

export function addToBasket () {
    // ========================================================================
    // Add to basket control.
    //
    // Forms for adding products to basket and editing order quantities.
    // Uses AJAX to update basket.
    // ========================================================================
    let focussedInput

    const forms = document.querySelectorAll('.js-add-to-basket')

    forms.forEach(form => {
        addToBasketInit(form)
    })

    function addToBasketInit (form, container = document.body) {
        const validationMessageMarker = form.closest('.js-validation-message-marker') || form.closest('.product-table')
        form.validation = new Validation(form, {
            messages: {
                target: validationMessageMarker,
                insert: 'before'
            },
            tooltip: {
                position: {
                    container,
                    elementAnchorPoint: 'center bottom',
                    targetAnchorPoint: 'center top',
                    offset: {
                        x: 0,
                        y: -3
                    }
                }
            }
        })
    }

    // Form submission event handlers.
    document.addEventListener('submit', handleSubmit)
    document.addEventListener('validation:valid', handleValid)
    document.addEventListener('validation:invalid', handleInvalid)

    function handleSubmit (event) {
        if (!event.target.matches('.js-add-to-basket')) {
            return
        }

        const form = event.target
        form.classList.add('is-loading')

        if (form.classList.contains('is-added')) {
            // Trigger a GA4 `button-click` custom event on updating an item in basket.
            window.tagManagerDataLayer = window.tagManagerDataLayer || []
            tagManagerDataLayer.push({
                'event': 'button-click',
                'event_data': {
                    'value': 'update-basket'
                }
            })
        }
    }

    function handleValid (event) {
        if (!event.target.matches('.js-add-to-basket')) {
            return
        }
        const form = event.target
        const forms = new Set()
        const input = form.querySelector('.js-qty')
        const quantity = parseInt(input.value)
        const data = event.detail.response.data
        const record = event.detail.response.record
        const productCode = record.product_code.value
        const inputs = document.querySelectorAll('.js-product-code[value="' + productCode + '"]')
        const formIsRelatedItem = form.closest('.js-related-items') !== null

        inputs.forEach(input => {
            const form = input.closest('.js-add-to-basket')
            if (form) {
                forms.add(form)
            }
        })

        if (String(record.quantity.value).trim() !== input.value.trim()) {
            // Input qty does not match qty saved - mark form as dirty.
            forms.forEach(form => {
                form.classList.add('is-dirty', 'is-added')
            })
        } else if (quantity > 0) {
            // Input quantity value is over zero - set form to populated/valid state.
            forms.forEach(form => {
                form.classList.remove('is-dirty')
                form.classList.add('is-added')
                form.querySelector('.js-qty').value = quantity
            })
        } else {
            // Input quantity value is 0 or empty/NaN - reset form to default state.
            const sellingMultiple = input.dataset.sellingMultiple
            forms.forEach(form => {
                form.classList.remove('is-added', 'is-dirty')
                form.querySelector('.js-qty').value = sellingMultiple
            })
        }

        // Set a data attribute to record the last saved value.
        forms.forEach(form => {
            form.querySelector('.js-qty').dataset.previousValue = record.quantity.value
        })

        // Remove loading class from increment buttons.
        form.querySelectorAll('.js-increase, .js-decrease').forEach(button => {
            button.classList.remove('is-loading')
        })

        // Update basket info in site header.
        document.querySelector('.js-navbar-basket-total').innerHTML = data.basketTotal
        document.querySelector('.js-navbar-basket-count').innerHTML = data.basketProductCount

        // Show add-to-basket confirmation.
        const templateAtbConfirmation = document.querySelector('.js-template-atb-confirmation')
        if (!formIsRelatedItem && templateAtbConfirmation ) {
            if (data.confirmation.message !== '') {
                // Show add-to-basket confirmation.
                showConfirmation(form, data.confirmation)
            } else {
                // Hide any existing add-to-basket confirmation.
                const confirmation = form.closest('.js-product').nextElementSibling
                if (confirmation && confirmation.classList.contains('js-atb-confirmation')) {
                    hideConfirmation(confirmation)
                }
            }

            // Remove all other add-to-basket confirmations.
            const thisConfirmation = form.closest('.js-product').nextElementSibling
            document.querySelectorAll('.js-atb-confirmation')
                .forEach(confirmation => {
                    if (confirmation !== thisConfirmation) {
                        hideConfirmation(confirmation)
                    }
                })
        }
    }

    function handleInvalid (event) {
        if (!event.target.matches('.js-add-to-basket')) {
            return
        }
        const form = event.target
        form.querySelectorAll('.js-increase, .js-decrease').forEach(button => {
            button.classList.remove('is-loading')
        })
    }

    function showConfirmation (form, data) {
        // Create and insert the add-to-basket confirmation.
        const productRow = form.closest('.js-product')

        let confirmation
        if (form.dataset.viewport === 'mobile') {
            confirmation = document.querySelector('.js-template-atb-confirmation-mobile').content.cloneNode(true)
        } else {
            confirmation = document.querySelector('.js-template-atb-confirmation').content.cloneNode(true)
        }
        confirmation = confirmation.querySelector('.js-atb-confirmation')

        const atbMessage = confirmation.querySelector('.js-atb-message')
        atbMessage.innerHTML = data.message

        const relatedItems = confirmation.querySelector('.js-related-items')

        if (relatedItems && data.related_items != '') {
            relatedItems.innerHTML = data.related_items
            const addToBasketForms = relatedItems.querySelectorAll('.js-add-to-basket')
            addToBasketForms.forEach(form => {
                addToBasketInit(form, relatedItems)
            })
        } else if (relatedItems) {
            relatedItems.remove()
        }

        // Insert the add-to-basket confirmation after the product row.
        const nextSibling = productRow.nextElementSibling
        const atbWrapper = confirmation.querySelector('.atb-confirmation__wrapper')
        if (nextSibling && nextSibling.classList.contains('js-atb-confirmation')) {
            // Replace an existing confirmation (no animation).
            productRow.nextElementSibling.replaceWith(confirmation)
            atbWrapper.classList.remove('is-hidden')
        } else {
            // Insert a new confirmation (with animation).
            productRow.after(confirmation)
            $(atbWrapper).removeClass('is-hidden', 500)
        }
    }

    function hideConfirmation (element) {
        // Hide an add-to-basket confirmation.
        const atbWrapper = element.querySelector('.atb-confirmation__wrapper')
        $(atbWrapper).addClass('is-hidden', 500, () => {
            element.remove()
        })
    }

    // ========================================================================
    // Quantity Increment/Decrement buttons.
    // ========================================================================

    let interval
    let timeout
    let autoIncrementing = false

    function restrictNumeric (event) {
        // Restrict Keypresses to numeric and select non-printable keys.
        const keyCode = event.which

        if ((!event.shiftKey && keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
            // Numeric key
            return true
        } else if (event.controlKey || event.metaKey) {
            // allow Ctrl+? & Command+? key combinations
            return true
        } else if ($.inArray(keyCode, [46, 8, 9, 27, 13, 110, 35, 36, 37, 38, 39, 40]) !== -1) {
            // allow select non-printable keys: backspace, delete, tab, escape, home, end, left, right, down, up
            return true
        }
        return false

    }

    function autoIncrementStart (input, increment, min, max) {
        // Increase input value by specified increment (optionally resticted by min & max values)
        // After 400ms increment value every 200ms until interval is cleared.
        let oldValue = parseInt(input.value)
        if (isNaN(oldValue)) {
            oldValue = 0
        }
        let newValue = oldValue + increment

        if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
            // Increment input value.
            input.value = parseInt(newValue)
            $(input).trigger('change')
            // Auto-increment after delay.
            autoIncrementing = true
            window.clearTimeout(timeout)
            timeout = window.setTimeout(function () {
                window.clearInterval(interval)
                interval = window.setInterval(function () {
                    oldValue = parseInt(input.value)
                    if (isNaN(oldValue)) {
                        oldValue = 0
                    }
                    newValue = oldValue + increment
                    if ((min === undefined || newValue >= min) && (max === undefined || newValue <= max)) {
                        input.value = parseInt(newValue)
                        $(input).trigger('change')
                    } else {
                        // Stop auto incrementing.
                        autoIncrementStop()
                    }
                }, 200)
            }, 400)
        }
    }

    function autoIncrementStop () {
        // Clear timers and select input text.
        window.clearTimeout(timeout)
        window.clearInterval(interval)
        autoIncrementing = false
    }

    //--------------------------------------------------------------------------
    // Quantity input field event handlers.
    //--------------------------------------------------------------------------

    // On click, select all text and store focussed input value.
    const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints

    document.body.addEventListener('click', function (event) {
        if (event.target.matches('.js-add-to-basket .js-qty')) {
            if (!isTouch && (!focussedInput || focussedInput !== event.target)) {
                $(event.target).textrange('set', 'all')
            }
            focussedInput = event.target
        }
    })

    document.body.addEventListener('blur', function (event) {
        if (event.target.matches('.js-add-to-basket .js-qty')) {
            // On Blur: reset the stored focussed input.
            focussedInput = undefined
        }
    })

    document.body.addEventListener('keydown', function (event) {
        if (event.target.matches('.js-add-to-basket .js-qty')) {
            restrictNumeric(event)
        } else {
            return
        }
        const sellingMultiple = parseInt(event.target.dataset.sellingMultiple) || 1
        let increment
        if (event.which === 40) {
            // Down arrow key
            increment = -1 * sellingMultiple
        } else if (event.which === 38) {
            // Up arrow key
            increment = sellingMultiple
        } else {
            // Don't auto increment
            return true
        }
        if (!autoIncrementing) {
            autoIncrementStart(event.target, increment, 0)
        }
        return false
    })

    document.body.addEventListener('keyup', function (event) {
        if (!event.target.matches('.js-add-to-basket .js-qty')) {
            return
        }
        const input = event.target
        const form = input.closest('.js-add-to-basket')
        const value = input.value
        const previousValue = input.dataset.previousValue

        if (value.trim() !== String(previousValue).trim()) {
            // Quantity value has changed since previous save - show 'Update' button.
            form.classList.add('is-dirty')
        }

        if (autoIncrementing) {
            autoIncrementStop()
            return false
        }
    })

    //--------------------------------------------------------------------------
    // Quantity Increment/decrement buttons event handlers.
    //--------------------------------------------------------------------------

    // Use touch feature detection to set which pointer-events to use.
    const pointerEvents = {
        down: 'mousedown',
        up: 'mouseup',
    }

    if (isTouch) {
        pointerEvents.down = 'touchstart'
        pointerEvents.up = 'touchend'
    }

    document.body.addEventListener(pointerEvents.down, handlePointerDown)
    document.body.addEventListener(pointerEvents.up, handlePointerUp)
    document.body.addEventListener('mouseout', handleMouseOut)

    function handlePointerDown (event) {
        if (!event.target.matches('.js-add-to-basket .js-increase, .js-add-to-basket .js-decrease')) {
            return
        }
        const target = event.target
        const form = target.closest('.js-add-to-basket')
        const input = form.querySelector('.js-qty')
        const sellingMultiple = parseInt(input.dataset.sellingMultiple) || 1
        let increment

        // Cancel scheduled form submssion.
        const timeout = form.dataset.submitTimeout
        window.clearTimeout(timeout)

        // Abort any in-progress validation requests.
        if (form.validation.state === 'validating') {
            form.validation.abort()
        }

        // Remove button loading state.
        form.querySelectorAll('.js-increase, .js-decrease').forEach(button => {
            button.classList.remove('is-loading')
        })

        if (event.type === 'touchstart') {
            if (target.matches('.js-decrease')) {
                // touchstart event on the decrease qty button.
                increment = -1 * sellingMultiple
            } else if (target.matches('.js-increase')) {
                // touchstart event on the increase qty button.
                increment = sellingMultiple
            }
        } else if (event.which === 1 && target.matches('.js-decrease')) {
            // Left mouse button event on the decrease qty button.
            increment = -1 * sellingMultiple
        } else if (event.which === 1 && target.matches('.js-increase')) {
            // Left mouse button event on the increase qty button.
            increment = sellingMultiple
        }

        if (!autoIncrementing) {
            autoIncrementStart(input, increment, 0)
        }
    }

    function handlePointerUp (event) {
        if (!event.target.matches('.js-add-to-basket .js-increase, .js-add-to-basket .js-decrease')) {
            return
        }

        // Stop auto-incrementing quantity on button release.
        if (autoIncrementing) {
            autoIncrementStop()
        }

        // Abort any in-progress validation requests and resubmit form.
        const target = event.target
        const form = target.closest('.js-add-to-basket')

        // Cancel scheduled form submssion.
        let timeout = form.dataset.submitTimeout
        window.clearTimeout(timeout)

        // Abort any in-progress validation requests.
        if (form.validation.state === 'validating') {
            form.validation.abort()
        }

        // Remove button loading state.
        form.querySelectorAll('.js-add-to-basket .js-increase, .js-add-to-basket .js-decrease').forEach(button => {
            button.classList.remove('is-loading')
        })

        // Schedule form submission.
        timeout = window.setTimeout(function () {
            // Update the pressed increment button to show loading state.

            form.validation.submit()
        }, 650)
        form.dataset.submitTimeout = timeout
    }

    function handleMouseOut (event) {
        if (!event.target.matches('.js-add-to-basket .js-increase, .js-add-to-basket .js-decrease')) {
            return
        }

        // Stop auto-incrementing quantity on button release.
        if (autoIncrementing) {
            autoIncrementStop()
        }
    }

    // ======================================================================
    // Add-to-basket confirmation.
    //
    // Shows confirmation message and related items when user adds to basket.
    // ======================================================================

    // Dismiss button - remove confirmation on click.
    document.body.addEventListener('click', function (event) {
        if (!event.target.closest('.js-atb-close')) {
            return
        }
        const confirmation = event.target.closest('.js-atb-confirmation')
        $('.atb-confirmation__wrapper', confirmation).addClass('is-hidden', 500, () => {
            confirmation.remove()
        })
    })

    // Close add-to-basket confirmation on 'esc' keydown.
    document.addEventListener('keydown', function (event) {
        if (event.keyCode === 27) {
            const confirmations = document.querySelectorAll('.js-atb-confirmation')

            confirmations.forEach(confirmation => {
                $('.atb-confirmation__wrapper', confirmation).addClass('is-hidden', 500, () => {
                    confirmation.remove()
                })
            })
        }
    })
}
